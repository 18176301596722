import React from 'react'
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { FilterRow } from './filter-row'
import type { FilterField, FilterItem } from './types'

interface FilterGroupProps {
  groupId: string
  items: FilterItem[]
  fields: FilterField[]
  onUpdateItem: (item: FilterItem) => void
  onAddCondition: (parentId: string) => void
  onAddGroup: (parentId: string) => void
  onDeleteItem: (id: string) => void
  level: number
  activeItemId: string | null
}

const levelColors = [
  'rgba(94,209,162,0.3)',
  'rgba(252,210,76,0.3)',
  'rgba(72,101,204,0.3)',
]

export function FilterGroup({
  groupId,
  items,
  fields,
  onUpdateItem,
  onAddCondition,
  onAddGroup,
  onDeleteItem,
  level,
  activeItemId,
}: FilterGroupProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const group = items.find((item) => item.id === groupId)
  const childItems = items.filter((item) => item.parentId === groupId)

  const activeItem = items.find((item) => item.id === activeItemId)
  const isRootActive = activeItem?.parentId === 'root'
  const showChildren = !isRootActive || level === 0

  const sortable = groupId !== 'root'
  const { setNodeRef: groupNodeRef, transform } = useSortable({
    id: groupId,
    disabled: !sortable,
  })

  if (!group || group.type !== 'group') return null

  const groupStyle = {
    transform:
      sortable && transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0px)`
        : undefined,
    transition: sortable ? 'transform 150ms ease' : undefined,
  }

  return (
    <Box
      ref={sortable ? groupNodeRef : undefined}
      style={groupStyle}
      sx={{
        backgroundColor: levelColors[level - 1] || levelColors[2],
        p: 2,
        borderRadius: 1,
        boxShadow: 1,
        mb: 2,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <ToggleButtonGroup
          value={group.groupType}
          exclusive
          onChange={(event, value) => {
            if (value) onUpdateItem({ ...group, groupType: value })
          }}
          sx={{ mr: 2 }}
        >
          <ToggleButton value="AND" sx={{ fontWeight: 'bold' }}>
            E
          </ToggleButton>
          <ToggleButton value="OR" sx={{ fontWeight: 'bold' }}>
            OU
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography>
          {group.groupType === 'AND'
            ? 'Todas as condições devem ser verdadeiras'
            : 'Qualquer condição pode ser verdadeira'}
        </Typography>
        <IconButton onClick={handleMenuOpen} sx={{ ml: 'auto' }}>
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              onAddCondition(groupId)
              handleMenuClose()
            }}
          >
            Adicionar condição
          </MenuItem>
          {level < 3 && (
            <MenuItem
              onClick={() => {
                onAddGroup(groupId)
                handleMenuClose()
              }}
            >
              Adicionar grupo
            </MenuItem>
          )}
          {groupId !== 'root' && (
            <MenuItem
              onClick={() => {
                onDeleteItem(groupId)
                handleMenuClose()
              }}
            >
              Excluir grupo
            </MenuItem>
          )}
        </Menu>
      </Box>
      <SortableContext
        items={childItems.map((item) => item.id)}
        strategy={verticalListSortingStrategy}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {showChildren &&
            childItems.map((item) => (
              <Box key={item.id} sx={{ mt: 2 }}>
                {item.type === 'condition' ? (
                  <FilterRow
                    item={item}
                    fields={fields}
                    onUpdate={onUpdateItem}
                    onDelete={() => onDeleteItem(item.id)}
                    onAddSubLevel={() => onAddGroup(item.id)}
                  />
                ) : (
                  <FilterGroup
                    groupId={item.id}
                    items={items}
                    fields={fields}
                    onUpdateItem={onUpdateItem}
                    onAddCondition={onAddCondition}
                    onAddGroup={onAddGroup}
                    onDeleteItem={onDeleteItem}
                    level={level + 1}
                    activeItemId={activeItemId}
                  />
                )}
              </Box>
            ))}
        </Box>
      </SortableContext>
    </Box>
  )
}
