import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { FilterBuilder } from '../../component/classification-builder/filter-builder'
import {
  CompanyClassificationRuleConditionalsConditionEnum,
  CompanyClassificationRuleConditionalsVariableEnum,
  FilterItem,
} from '../../component/classification-builder/types'
import { DefaultHeader } from '../../component/header/header'
import BlueHeader from '../../assets/background/header-blue.png'
import { NotificationBellIcon } from '../../component/icons/notification-bell'
import { BaseInternalHeader } from '../../component/base-component/base-internal-header'
import { BasePageTitle } from '../../component/base-component/base-page-title'
import {
  Classifier,
  ClassifierState,
} from '../../component/classification-builder/actions/action-section'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Api } from '../../service/service-api'

const initialItems: FilterItem[] = [
  { id: 'root', type: 'group', parentId: null, groupType: 'AND' },
]

export interface Root {
  id: string
  accountingChartItem: {
    code: string
  }
  company: {
    id: string
    name: string
    cnpj: string
    status: number
    createdAt: string
    updatedAt: string
    owner: any
    users: any[]
  }
  name: string
  conditionals: {
    data: any
    name: string
    variable: string
    condition: string
  }[]
  credit: string
  debit: string
  historic: any
  financialCategory: any
  generalRule: boolean
  createdAt: string
  updatedAt: string
}

function getValue(field: string, data: any) {
  field = field.toLowerCase()

  if (!data) {
    return ''
  }

  if (field === 'value') {
    return data.value?.value
  }

  if (field === 'date') {
    return data.init
  }

  if (field === 'thirdpartyname') {
    return data.thirdParty.name
  }

  if (field === 'thirdpartydocumentnumber') {
    return data.thirdParty.documentNumber
  }

  if (field === 'description') {
    return data.description
  }
}

export function NewClassificationRules() {
  const { companyId, classificationRuleId } = useParams()

  const [items, setItems] = useState<FilterItem[]>(initialItems)
  const [actions, setActions] = useState<ClassifierState>([
    {
      id: 'root',
      type: 'credit',
      value: '',
    },
  ])

  const [code, setCode] = useState('')
  const [ruleName, setRuleName] = useState('')
  const [isGeneralRule, setIsGeneralRule] = useState(false)

  const navigate = useNavigate()

  const { data: classificationRule, isLoading } = useQuery<Root>({
    queryKey: ['classificationRule', companyId, classificationRuleId],
    queryFn: async () => {
      return Api.axios
        .get(
          `v1/company/${companyId}/classification-rule/${classificationRuleId}`,
        )
        .then((res) => res.data)
    },
    enabled: classificationRuleId !== 'new',
  })

  useEffect(() => {
    if (!classificationRule?.conditionals) {
      return
    }

    setCode(classificationRule.accountingChartItem.code)
    setRuleName(classificationRule.name)
    setIsGeneralRule(classificationRule.generalRule)

    const items = [
      {
        id: 'root',
        type: 'group' as const,
        parentId: null,
        groupType: 'AND' as const,
      },
      ...classificationRule.conditionals.map((conditional) => ({
        id: Math.random().toString(36).substring(2, 15),
        type: 'condition' as const,
        parentId: 'root',
        variable:
          conditional.variable as CompanyClassificationRuleConditionalsVariableEnum,
        operator:
          conditional.condition as CompanyClassificationRuleConditionalsConditionEnum,
        value: getValue(conditional.variable, conditional.data),
      })),
    ]

    setItems(items)

    const loadedActions = [
      {
        id: Math.random().toString(36).substring(2, 15),
        type: 'credit',
        value: classificationRule.credit,
      },
      {
        id: Math.random().toString(36).substring(2, 15),
        type: 'debit',
        value: classificationRule.debit,
      },
      {
        id: Math.random().toString(36).substring(2, 15),
        type: 'financialCategory',
        value: classificationRule.financialCategory,
      },
      {
        id: Math.random().toString(36).substring(2, 15),
        type: 'historic',
        value: classificationRule.historic,
      },
    ] as const

    setActions(loadedActions.filter((v) => !!v.value))
  }, [classificationRule])

  const { mutate, isPending } = useMutation({
    mutationKey: ['createClassificationRule', 'updateClassificationRule'],
    mutationFn: async (data: any) => {
      return await Api.axios(
        `v1/company/${companyId}/classification-rule/${classificationRuleId === 'new' ? '' : classificationRuleId}`,
        {
          method: classificationRuleId === 'new' ? 'POST' : 'PUT',
          data,
        },
      )
    },
    onSuccess: () => navigate(`/company/${companyId}/classification-rules`),
  })

  const handleCreate = () => {
    const conditionsOnly = items.filter((item) => item.type === 'condition')

    const credit = actions.find((action) => action.type === 'credit')?.value
    const debit = actions.find((action) => action.type === 'debit')?.value
    const historic = actions.find((action) => action.type === 'historic')?.value
    const financialCategory = actions.find(
      (action) => action.type === 'financialCategory',
    )?.value

    const data = {
      accountingChartItem: {
        code,
      },
      classificationRule: {
        name: ruleName,
        conditionals: conditionsOnly.map((condition) => {
          const value = (() => {
            if (
              condition.variable ===
              CompanyClassificationRuleConditionalsVariableEnum.VALUE
            ) {
              return {
                value: {
                  value: condition.value,
                },
              }
            }

            if (
              condition.variable ===
              CompanyClassificationRuleConditionalsVariableEnum.DATE
            ) {
              return {
                date: {
                  init: condition.value,
                  end: condition.value,
                },
              }
            }

            if (
              condition.variable ===
              CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY_NAME
            ) {
              return {
                thirdParty: {
                  name: condition.value,
                  documentNumber: '',
                },
              }
            }

            if (
              condition.variable ===
              CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY_DOCUMENT_NUMBER
            ) {
              return {
                thirdParty: {
                  name: '',
                  documentNumber: condition.value,
                },
              }
            }

            if (
              condition.variable ===
              CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION
            ) {
              return {
                description: {
                  description: condition.value,
                },
              }
            }
          })()

          return {
            name: condition.variable,
            variable: condition.variable,
            condition: condition.operator,
            data: {
              ...value,
            },
          }
        }),
        generalRule: isGeneralRule,
        credit,
        debit,
        historic,
        financialCategory,
      },
    }

    mutate(data)
  }

  if (isLoading) {
    return null
  }

  return (
    <Stack width="100%" height="100%" sx={{ backgroundColor: '#F4F8FA' }}>
      <DefaultHeader
        containerStyle={{
          height: '56px',
          backgroundImage: `url(${BlueHeader})`,
          alignItems: 'flex-start',
          paddingTop: '16px',
          zIndex: 9,
          position: 'relative',
        }}
        buttons={
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{
                backgroundColor: '#ffffff',
                color: '#4865CC',
                marginLeft: '24px',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
              }}
              onClick={() => console.log('empty')}
            >
              <NotificationBellIcon />
            </IconButton>
          </Stack>
        }
      />
      <Stack width="100%" direction="column" gap="32px" padding="32px">
        <Stack>
          <BaseInternalHeader
            leftComponent={
              <BasePageTitle color="#1E3466" text="Regras de classificação" />
            }
          />
        </Stack>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }} gap="8px">
          <TextField
            label="Código"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            variant="outlined"
          />
          <TextField
            label="Nome da regra"
            value={ruleName}
            onChange={(e) => setRuleName(e.target.value)}
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreate}
            disabled={isPending}
          >
            {classificationRuleId !== 'new' ? 'Salvar regra' : 'Criar regra'}
          </Button>
        </Box>
        <Box width="100%">
          <FilterBuilder items={items} setItems={setItems} />
        </Box>
        <Box width="100%">
          <Typography variant="h5" sx={{ mb: 2 }} fontWeight="bold">
            Execute as seguintes ações
          </Typography>
          <Classifier state={actions} setState={setActions} />
        </Box>
      </Stack>
    </Stack>
  )
}
