import React, { ComponentPropsWithoutRef, useState } from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { MoreVert, DragIndicator } from '@mui/icons-material'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
  FilterItem,
  FilterField,
  FilterOperator,
  FilterOperators,
  CompanyClassificationRuleConditionalsConditionEnum,
  CompanyClassificationRuleConditionalsVariableEnum,
} from './types'

interface FilterRowProps extends ComponentPropsWithoutRef<typeof Box> {
  item: FilterItem
  fields: FilterField[]
  onUpdate: (item: FilterItem) => void
  onDelete: () => void
  onAddSubLevel: () => void
}

export function FilterRow({
  item,
  fields,
  onUpdate,
  onDelete,
  onAddSubLevel,
  ...props
}: FilterRowProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  if (item.type !== 'condition') return null

  const handleFieldChange = (event: SelectChangeEvent) => {
    const value = event.target
      .value as CompanyClassificationRuleConditionalsVariableEnum
    onUpdate({ ...item, variable: value, value: '' })
  }

  const handleOperatorChange = (event: SelectChangeEvent) => {
    const value = event.target.value as FilterOperator
    onUpdate({ ...item, operator: value, value: '' })
  }

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...item, value: event.target.value })
  }

  const selectedField = fields.find((field) => field.value === item.variable)
  const availableConditions = (selectedField?.supportedConditions ?? []).map(
    (condition) => ({
      label: FilterOperators[condition],
      value: condition,
    }),
  )

  return (
    <Box
      ref={setNodeRef}
      style={style}
      display="flex"
      alignItems="center"
      gap={2}
      p={2}
      borderRadius={999}
      boxShadow={1}
      bgcolor="white"
      {...props}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{ cursor: 'grab', opacity: 0.75 }}
        {...attributes}
        {...listeners}
      >
        <DragIndicator />
      </Box>
      <Select
        value={item.variable}
        onChange={handleFieldChange}
        displayEmpty
        sx={{ width: 200 }}
      >
        {fields.map((field) => (
          <MenuItem key={field.value} value={field.value}>
            {field.name}
          </MenuItem>
        ))}
      </Select>
      <Select
        value={item.operator || ''}
        onChange={handleOperatorChange}
        displayEmpty
        sx={{ width: 150 }}
      >
        {availableConditions.map((filter) => (
          <MenuItem key={filter.value} value={filter.value}>
            {filter.label}
          </MenuItem>
        ))}
      </Select>

      {/* Basic value input */}
      {item.operator &&
        item.operator !==
          CompanyClassificationRuleConditionalsConditionEnum.BETWEEN &&
        item.operator !==
          CompanyClassificationRuleConditionalsConditionEnum.EQUAL && (
          <TextField
            value={item.value || ''}
            onChange={handleValueChange}
            placeholder="Digite o valor"
            sx={{ width: 200 }}
          />
        )}

      {/* Start and End inputs for between when variable is not Date */}
      {/* {item.operator === */}
      {/*  CompanyClassificationRuleConditionalsConditionEnum.BETWEEN && */}
      {/*  item.variable !== */}
      {/*    CompanyClassificationRuleConditionalsVariableEnum.DATE && ( */}
      {/*    <Box> */}
      {/*      <TextField */}
      {/*        value={item.value?.start || ''} */}
      {/*        onChange={(e) => */}
      {/*          onUpdate({ */}
      {/*            ...item, */}
      {/*            value: { */}
      {/*              ...(typeof item.value === 'object' && item.value !== null */}
      {/*                ? item.value */}
      {/*                : { start: '', end: '' }), */}
      {/*              start: e.target.value, */}
      {/*            }, */}
      {/*          }) */}
      {/*        } */}
      {/*        placeholder="Start Value" */}
      {/*        sx={{ width: 200 }} */}
      {/*      /> */}
      {/*      <TextField */}
      {/*        value={item.value?.end || ''} */}
      {/*        onChange={(e) => */}
      {/*          onUpdate({ */}
      {/*            ...item, */}
      {/*            value: { */}
      {/*              ...(typeof item.value === 'object' && item.value !== null */}
      {/*                ? item.value */}
      {/*                : { start: '', end: '' }), */}
      {/*              end: e.target.value, */}
      {/*            }, */}
      {/*          }) */}
      {/*        } */}
      {/*        placeholder="End Value" */}
      {/*        sx={{ width: 200 }} */}
      {/*      /> */}
      {/*    </Box> */}
      {/*  )} */}

      {/* Start and End inputs for date or between */}
      {item.operator ===
        CompanyClassificationRuleConditionalsConditionEnum.BETWEEN &&
        item.variable ===
          CompanyClassificationRuleConditionalsVariableEnum.DATE && (
          <Box>
            <TextField
              type="date"
              onChange={(e) =>
                onUpdate({
                  ...item,
                  value: {
                    ...(typeof item.value === 'object' && item.value !== null
                      ? item.value
                      : { start: '', end: '' }), // Default to empty for both
                    start: e.target.value,
                  },
                })
              }
              placeholder="Start Date"
              sx={{ width: 200 }}
            />
            <TextField
              type="date"
              onChange={(e) =>
                onUpdate({
                  ...item,
                  value: {
                    ...(typeof item.value === 'object' && item.value !== null
                      ? item.value
                      : { start: '', end: '' }),
                    end: e.target.value,
                  },
                })
              }
              placeholder="End Date"
              sx={{ width: 200 }}
            />
          </Box>
        )}

      {item.operator ===
        CompanyClassificationRuleConditionalsConditionEnum.EQUAL && (
        <TextField
          value={item.value || ''}
          onChange={handleValueChange}
          placeholder="Digite o valor"
          sx={{ width: 200 }}
        />
      )}

      <IconButton onClick={handleMenuOpen}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            onDelete()
            handleMenuClose()
          }}
        >
          Excluir
        </MenuItem>
      </Menu>
    </Box>
  )
}
