export enum CompanyClassificationRuleConditionalsConditionEnum {
  CONTAINS = 'Contains',
  BETWEEN = 'Between',
  GREATER_THAN = 'GreaterThan',
  LESS_THAN = 'LessThan',
  EQUAL = 'Equal',
  NO_CONTAIN = 'NoContain',
}

export enum CompanyClassificationRuleConditionalsVariableEnum {
  VALUE = 'Value',
  DATE = 'Date',
  THIRD_PARTY_NAME = 'ThirdPartyName',
  THIRD_PARTY_DOCUMENT_NUMBER = 'ThirdPartyDocumentNumber',
  DESCRIPTION = 'Description',
  ACCOUNT_ID = 'AccountId',
  BANK_ID = 'BankId',
  BANK_NAME = 'BankName',
  BANK_AGENCY = 'BankAgency',
  BANK_ACCOUNT_NUMBER = 'BankAccountNumber',
  PAYMENT_TYPE = 'PaymentType',
}

export const FilterOperators = {
  [CompanyClassificationRuleConditionalsConditionEnum.EQUAL]: 'Igual a',
  [CompanyClassificationRuleConditionalsConditionEnum.CONTAINS]: 'Contém',
  [CompanyClassificationRuleConditionalsConditionEnum.GREATER_THAN]:
    'Maior que',
  [CompanyClassificationRuleConditionalsConditionEnum.LESS_THAN]: 'Menor que',
  [CompanyClassificationRuleConditionalsConditionEnum.BETWEEN]: 'Entre',
  [CompanyClassificationRuleConditionalsConditionEnum.NO_CONTAIN]: 'Não contém',
} as const

export type FilterOperator = keyof typeof FilterOperators

export type FilterValue =
  | string
  | number
  | { start: string | number; end: string | number } // For 'between' or date range conditions

export type FilterItem = {
  id: string
  type: 'condition' | 'group'
  parentId: string | null
  groupType?: 'AND' | 'OR'
  variable?: CompanyClassificationRuleConditionalsVariableEnum // Use enum for strong typing
  operator?: FilterOperator
  value?: FilterValue // Support for complex values and conditions
}

export type FilterField = {
  name: string
  value: CompanyClassificationRuleConditionalsVariableEnum // Use enum for strong typing
  type: 'string' | 'number' | 'select' | 'date'
  options?: { label: string; value: string }[]
  supportedConditions?: FilterOperator[]
}
