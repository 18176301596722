import React, { useState } from 'react'
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  closestCenter,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { FilterGroup } from './filter-group'
import {
  CompanyClassificationRuleConditionalsConditionEnum,
  CompanyClassificationRuleConditionalsVariableEnum,
  FilterField,
  FilterItem,
} from './types'
import { Box, Typography } from '@mui/material'

const availableFields: FilterField[] = [
  {
    name: 'Valor',
    value: CompanyClassificationRuleConditionalsVariableEnum.VALUE,
    type: 'number',
    supportedConditions: [
      CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
      CompanyClassificationRuleConditionalsConditionEnum.GREATER_THAN,
      CompanyClassificationRuleConditionalsConditionEnum.LESS_THAN,
      // CompanyClassificationRuleConditionalsConditionEnum.BETWEEN,
    ],
  },
  {
    name: 'Descrição',
    value: CompanyClassificationRuleConditionalsVariableEnum.DESCRIPTION,
    type: 'string',
    supportedConditions: [
      CompanyClassificationRuleConditionalsConditionEnum.CONTAINS,
      CompanyClassificationRuleConditionalsConditionEnum.NO_CONTAIN,
      CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
    ],
  },
  {
    name: 'Data',
    value: CompanyClassificationRuleConditionalsVariableEnum.DATE,
    type: 'date',
    supportedConditions: [
      CompanyClassificationRuleConditionalsConditionEnum.BETWEEN,
      CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
    ],
  },
  {
    name: 'Banco',
    value: CompanyClassificationRuleConditionalsVariableEnum.BANK_NAME,
    type: 'string',
    supportedConditions: [
      CompanyClassificationRuleConditionalsConditionEnum.CONTAINS,
      CompanyClassificationRuleConditionalsConditionEnum.NO_CONTAIN,
      CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
    ],
  },
  {
    name: 'Cliente/Fornecedor - Nome',
    value: CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY_NAME,
    type: 'string',
    supportedConditions: [
      CompanyClassificationRuleConditionalsConditionEnum.CONTAINS,
      CompanyClassificationRuleConditionalsConditionEnum.NO_CONTAIN,
      CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
    ],
  },
  {
    name: 'Cliente/Fornecedor - Documento',
    value:
      CompanyClassificationRuleConditionalsVariableEnum.THIRD_PARTY_DOCUMENT_NUMBER,
    type: 'string',
    supportedConditions: [
      CompanyClassificationRuleConditionalsConditionEnum.CONTAINS,
      CompanyClassificationRuleConditionalsConditionEnum.NO_CONTAIN,
      CompanyClassificationRuleConditionalsConditionEnum.EQUAL,
    ],
  },
]

const generateUniqueId = () => Math.random().toString(36).substring(2, 15)

type FilterBuilderProps = {
  items: FilterItem[]
  setItems: React.Dispatch<React.SetStateAction<FilterItem[]>>
}

export function FilterBuilder({ items, setItems }: FilterBuilderProps) {
  const [activeId, setActiveId] = useState<string | null>(null)

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } }),
  )

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    setActiveId(null)
    if (over && active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }
  }

  const handleUpdateItem = (updatedItem: FilterItem) => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        if (item.id === updatedItem.id) {
          return {
            ...item,
            ...updatedItem,
            operator:
              availableFields.find(
                (field) => field.value === updatedItem.variable,
              )?.supportedConditions?.[0] ?? item.operator,
          }
        }
        return item
      }),
    )
  }

  const handleAddCondition = (parentId: string) => {
    const newCondition: FilterItem = {
      id: generateUniqueId(),
      type: 'condition',
      parentId,
      variable: CompanyClassificationRuleConditionalsVariableEnum.VALUE,
      operator: CompanyClassificationRuleConditionalsConditionEnum.BETWEEN,
      value: { start: '', end: '' },
    }

    setItems((prevItems) => [...prevItems, newCondition])
  }

  const handleAddGroup = (parentId: string) => {
    const newGroup: FilterItem = {
      id: generateUniqueId(),
      type: 'group',
      parentId,
      groupType: 'AND',
    }

    setItems((prevItems) => [...prevItems, newGroup])
  }

  const handleDeleteItem = (id: string) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id))
  }

  const activeItem = items.find((item) => item.id === activeId)

  return (
    <Box
      sx={{ maxWidth: '90%', bgcolor: '#f5f5f5', padding: 2, borderRadius: 2 }}
    >
      <Typography variant="h5" sx={{ mb: 2 }} fontWeight="bold">
        Quando a condição abaixo for verdadeira
      </Typography>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <FilterGroup
            groupId="root"
            items={items}
            fields={availableFields}
            onUpdateItem={handleUpdateItem}
            onAddCondition={handleAddCondition}
            onAddGroup={handleAddGroup}
            onDeleteItem={handleDeleteItem}
            level={0}
            activeItemId={activeId}
          />
        </SortableContext>
        <DragOverlay>
          {activeItem && (
            <Box
              sx={{
                p: 2,
                borderRadius: 1,
                boxShadow: 2,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                width: 'fit-content',
              }}
            >
              <Typography variant="body1" fontWeight="bold">
                {activeItem.type === 'group'
                  ? `Grupo: ${activeItem.groupType}`
                  : `Condição: ${
                      availableFields.find(
                        (field) => field.value === activeItem.variable,
                      )?.name ?? activeItem.variable
                    }`}
              </Typography>
              {activeItem.type === 'condition' && (
                <Typography variant="caption">
                  {activeItem.operator} {JSON.stringify(activeItem.value)}
                </Typography>
              )}
            </Box>
          )}
        </DragOverlay>
      </DndContext>
    </Box>
  )
}
