import {
  MenuItem,
  Select,
  TextField,
  IconButton,
  InputLabel,
  FormControl,
  Menu,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useState } from 'react'

interface ClassifierRowProps {
  id: string
  type: string
  value: string
  onTypeChange: (value: string) => void
  onValueChange: (value: string) => void
  onAddRow: () => void
  onRemoveRow: (id: string) => void
}

export function ClassifierRow({
  id,
  type,
  value,
  onTypeChange,
  onValueChange,
  onAddRow,
  onRemoveRow,
}: ClassifierRowProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAddRow = () => {
    onAddRow()
    handleClose()
  }

  const handleRemoveRow = () => {
    onRemoveRow(id)
    handleClose()
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        width: '100%',
      }}
    >
      <FormControl variant="outlined" style={{ minWidth: 200 }}>
        <InputLabel>Tipo</InputLabel>
        <Select
          value={type}
          onChange={(e) => onTypeChange(e.target.value as string)}
          label="Tipo"
        >
          <MenuItem value="debit">Débito</MenuItem>
          <MenuItem value="credit">Crédito</MenuItem>
          <MenuItem value="financialCategory">Categoria Financeira</MenuItem>
          <MenuItem value="historic">Histórico</MenuItem>
        </Select>
      </FormControl>

      <div
        style={{
          width: '100px',
          textAlign: 'center',
          fontSize: '14px',
          color: '#666',
        }}
      >
        Igual
      </div>

      <TextField
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        variant="outlined"
        style={{ minWidth: 200 }}
      />

      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleAddRow}>Adicionar nova linha</MenuItem>
        <MenuItem onClick={handleRemoveRow}>Remover linha</MenuItem>
      </Menu>
    </div>
  )
}
