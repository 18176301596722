import { Button, Box } from '@mui/material'
import { ClassifierRow } from './action-classifier'

const classificationTypes = {
  debit: 'Débito',
  credit: 'Crédito',
  financialCategory: 'Categoria Financeira',
  historic: 'Histórico',
}

export type ClassifierState = {
  id: string
  type: keyof typeof classificationTypes
  value: string
}[]

interface ClassifierProps {
  state: ClassifierState
  setState: React.Dispatch<React.SetStateAction<ClassifierState>>
}

export function Classifier({ state, setState }: ClassifierProps) {
  const addRow = () => {
    setState([
      ...state,
      {
        id: Math.random().toString(36).substring(2, 15),
        type: 'credit',
        value: '',
      },
    ])
  }

  const updateRow = (id: string, field: 'type' | 'value', newValue: string) => {
    setState((prev) =>
      prev.map((row) => (row.id === id ? { ...row, [field]: newValue } : row)),
    )
  }

  const removeRow = (id: string) => {
    setState((prev) => prev.filter((row) => row.id !== id))
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 600,
        p: 2,
      }}
    >
      <Box sx={{ mb: 3, display: 'flex', gap: 1 }}>
        <Button
          variant="contained"
          sx={{
            bgcolor: 'emerald.main',
            '&:hover': { bgcolor: 'emerald.dark' },
          }}
        >
          Classificar
        </Button>
        <Button variant="outlined" disabled={true}>
          Agrupar
        </Button>
        <Button variant="outlined" disabled={true}>
          Desmembrar
        </Button>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {state.map((row) => (
          <ClassifierRow
            id={row.id}
            key={row.id}
            type={row.type}
            value={row.value}
            onTypeChange={(value) => updateRow(row.id, 'type', value)}
            onValueChange={(value) => updateRow(row.id, 'value', value)}
            onAddRow={() => addRow()}
            onRemoveRow={(id) => removeRow(id)}
          />
        ))}
      </Box>
    </Box>
  )
}
